<template>
	<div class="formButton">
		<slot name="otherButtons" />
		<b-button variant="teal" size="sm" v-if="is_view == 0 && enable_save_draft" :disabled="permission_save == false || permission_save == null" @click="$emit('saveDraf')" class="mt-1 mr-1">
			<span v-text="$t('COMMON.BTN_SAVE_AS_DRAFT')"></span
		></b-button>
		<b-button type="submit" variant="success" size="sm" v-if="is_view == 0" :disabled="state == 0 || is_saving == true || permission_save == null || permission_save == false" class="mt-1">
			<b-icon v-if="is_saving" icon="arrow-clockwise" animation="spin-pulse"></b-icon>
			<i v-else class="fa fa-save"></i>
			<span v-text="saveText == null ? $t('COMMON.BTN_SAVE') : saveText"></span>
		</b-button>
		<b-button :to="editRoute" variant="success" size="sm" v-if="is_view == 1 && editRoute && (permission_edit == null || permission_edit == true)" :disabled="editState == 0" class="mt-1">
			<b-icon-pencil-square></b-icon-pencil-square>
			<span class="ml-1" v-text="$t('COMMON.BTN_EDIT')"></span>
		</b-button>
		<b-button variant="danger" :to="indexRoute" class="ml-2 mt-1" size="sm" v-if="indexRoute && (permission_list == null || permission_list == true)">
			<span v-text="$t('COMMON.BTN_BACK')"></span>
		</b-button>
	</div>
</template>

<script>
export default {
	name: "FormButton",
	props: {
		indexRoute: String,
		editRoute: null,
		is_view: null,
		modules: null,
		state: null,
		editState: null,
		saveText: null,
		permission_save: null,
		permission_list: null,
		permission_edit: null,
		is_saving: {
			default: false,
			type: Boolean
		},
		enable_save_draft: {
			type: Boolean,
			default: false
		}
	}
};
</script>
